<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="이름을 정자로 입력하세요." class="cardClassDetailInfo" topClass="topcolor-orange">
        <template v-if="attendeeData.attendeeCompleteFlag === 'Y'" slot="card-description">
          <font color="#C10015">※ 이미 서명제출하셨습니다.</font>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <VueSignaturePad id="signature" :width="windowWidth" height="200px" ref="signaturePad" />
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-card title="참석자 건강상태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-text
            :disabled="true"
            name="tbmName"
            label="TBM명"
            v-model="attendeeData.tbmName"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            :disabled="true"
            name="tbmDate"
            label="TBM일"
            type="date"
            v-model="attendeeData.tbmDate"
          ></c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            :required="true"
            :disabled="true"
            :editable="editable"
            :range="true"
            :minuteStep="10"
            type="time"
            label="TBM시간"
            name="workTime"
            v-model="workTime"
          />
        </div>
        <div class="col-12">
          <c-text
            :disabled="disabled"
            name="remark"
            label="참석자 비고"
            v-model="attendeeData.remark"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            name="tbmAttendeeDept"
            label="소속"
            v-model="attendeeData.tbmAttendeeDept"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            name="tbmAttendeeJob"
            label="직책"
            v-model="attendeeData.tbmAttendeeJob"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            name="tbmAttendeeName"
            label="참석자"
            v-model="attendeeData.tbmAttendeeName"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :disabled="disabled"
            codeGroupCd="TBM_HEALTH_STATUS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="healthStatusCd"
            label="건강상태"
            v-model="attendeeData.healthStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :disabled="disabled"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="정신적피로"
            name="mentalFlag"
            v-model="attendeeData.mentalFlag"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :disabled="disabled"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="심리적피로"
            name="phychologicalFlag"
            v-model="attendeeData.phychologicalFlag"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :disabled="disabled"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="육체적피로"
            name="physicalFlag"
            v-model="attendeeData.physicalFlag"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :disabled="disabled"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="신체적결함"
            name="physicalDefectFlag"
            v-model="attendeeData.physicalDefectFlag"
          />
        </div>
      </template>
    </c-card>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn flat v-if="attendeeData.attendeeCompleteFlag !== 'Y'" label="참석완료" color="blue" :showLoading="false" @btnClicked="saveSign" />
          <c-btn flat v-if="attendeeData.attendeeCompleteFlag !== 'Y'" label="서명삭제" color="red" :showLoading="false" @btnClicked="eraseSign" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import mobileConfig from '@/js/mobileConfig';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          tbmId: '',
          regUserId: '',
          chgUserId: '',
          plantCd: ''
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      updateSignUrl: '',
      windowWidth: '',
      attendeeData: {
        tbmId: '',
        tbmName: '',
        tbmDate: '',
        tbmStarTime: '', // 시작시간
        tbmEndTime: '', // 종료시간

        tbmAttendeeUserId: '',
        tbmAttendeeName: '',
        tbmAttendeeUserJob: '',
        tbmAttendeeUserDept: '',
        attendeeSign: '',
        mentalFlag: "N",
        phychologicalFlag: "N",
        physicalFlag: "N",
        physicalDefectFlag: "N",
        attendeeCompleteFlag: '',
        healthStatusCd: "THS0000001",
        remark: '',
        chgUserId: '',
      },
      workTime: [],
      flagItems: [
        { code: 'N', codeName: '아니다', },
        { code: 'Y', codeName: '그렇다', },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.attendeeData.attendeeCompleteFlag === 'Y'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.updateSignUrl = mobileConfig.sai.tbm.update.signature.url;
      this.getUrl = mobileConfig.sai.tbm.get.mobile.url;
      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad
      this.getUserDetail();
    },
    getUserDetail() {
      if (this.popupParam.plantCd) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.plantCd, this.$store.getters.user.userId);
        this.$http.type = 'GET';
        this.$http.param = {
          tbmId: this.popupParam.tbmId
        }
        this.$http.request((_result) => {
          if (_result.data) {
            this.attendeeData = _result.data
            this.workTime = [_result.data.tbmStartTime, _result.data.tbmEndTime]
            if (this.attendeeData.attendeeSign) {
              signaturePad.fromDataURL(this.attendeeData.attendeeSign);
            }
            if (_result.data.attendeeCompleteFlag === 'Y') {signaturePad.lockSignaturePad(); }
          } else {
            this.attendeeData.tbmAttendeeUserId = this.$store.getters.user.userId
          }
        })
      }
    },
    saveSign() {
      var data = ''
      if(signaturePad.saveSignature()) {data = signaturePad.saveSignature().data }
      this.attendeeData.attendeeSign = data;
      if (!this.attendeeData.attendeeSign) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '서명하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '서명등록하시겠습니까? \n(등록 시 관련교욱 참석이 완료됩니다.)',
          type: 'warning', // success / info / warning / error
          confirmCallback: () => {
            this.attendeeData.attendeeCompleteFlag = 'Y'
            this.attendeeData.chgUserId = this.$store.getters.user.userId
            this.$http.url = this.updateSignUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.attendeeData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$emit('closePopup');
            },);
          },
          cancelCallback: () => {
          },
      });
      }
    },
    eraseSign() {
      signaturePad.clearSignature();
      this.attendeeData.attendeeSign = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
    closePopUps() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '닫으시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$emit('closePopup')
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
<style>
#signature {
  border: double 1px transparent;
  border-radius: 2;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>